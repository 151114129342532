
import { defineComponent, reactive } from "vue";
import { gpf } from "@/utils/global-functions";
import { api } from "@/utils/api-config";

export default defineComponent({
  setup() {
    const configGet = api.configGet();
    const configXwfu = api.configXwfu();
    const listStatus = gpf.dataStatus();

    const titleTable: Array<string> = [
      "Title",
      "Status",
      "Created",
      "#",
    ];

    // "id": 2,
    // "slug": "info-pppk",
    // "title": "Info PPPK",
    // "isActive": 1,
    // "createdAt": "2021-04-21 22:20:40"

    const dataBK = reactive({
      data: [] as any,
      page: 1,
      pageSize: 10,
      search: "",
      paginate: {
        currentPage: 1,
        endIndex: 4,
        endPage: 2,
        pageSize: 10,
        pages: [],
        startIndex: 0,
        startPage: 0,
        totalItems: 0,
        totalPages: 0,
      } as any,
    });

    const getData = async () => {
      cash("#loadingBKadmin").show();

      const hide = () => cash("#loadingBKadmin").hide();
      const page = dataBK.page;
      const pageSize = dataBK.pageSize;
      const search = dataBK.search;
      const url = api.adminBlogKategori +
        "?search=" +
        search +
        "&page=" +
        page +
        "&pageSize=" +
        pageSize;

      const hitApi = await api.hitApiGet(url, hide);
      const ds = hitApi.data.diagnostic;
      const rs = hitApi.data.response;
      // console.log("response get du ", rs);

      dataBK.data = rs;
      dataBK.paginate = gpf.paginate(ds.totalData, page, pageSize, 3);

    };
    getData();

    const changePS = () => {
      getData();
    };

    const goToPage = (p: number) => {
      dataBK.page = p;
      getData();
    };

    const nextPage = () => {
      dataBK.page = dataBK.page + 1;
      getData();
    };

    const beforePage = () => {
      dataBK.page = dataBK.page - 1;
      getData();
    };

    const lastPage = () => {
      dataBK.page = dataBK.paginate.totalPages;
      getData();
    };

    return {
      titleTable,
      getData,
      dataBK,
      changePS,
      goToPage,
      nextPage,
      beforePage,
      lastPage,
      configGet,
      configXwfu,
      listStatus,
    };
  },
  data() {
    return {
      // for modal data bs
      section: "add",

      exDataBK: {
        id: 0,
        title: "",
        status: "1",
      },

      exDeleteBK: {
        id: 0,
        title: "",
      },

      listKodeKS: [],
    };
  },
  methods: {
    changeTS(v: any, f: any) {
      const val = v.detail.args[1].key;
      const ex: any = this.exDataBK;
      ex[f] = val;
    },
    async emptyField() {
      const ex = this.exDataBK;
      ex.title = "";
      ex.status = "1";
    },
    async addBK() {
      await this.emptyField();
      this.section = "add";
      gpf.showModal("#modal-data-bk");
    },
    async addBKApi() {
      gpf.gLoading.show();
      const hide = () => gpf.gLoading.hide();
      const ex = this.exDataBK;
      const params = new URLSearchParams();
      params.append("title", ex.title);
      params.append("status", ex.status);

      const url = api.adminBlogKategori;
      const hitApi = await api.hitApiPost(url, params, hide);
      await gpf.handleSds(hitApi);
      await gpf.hideModal("#modal-data-bk");
      await this.emptyField();
      await this.getData();

    },
    editBK(data: any) {
      this.section = "edit";

      const ex = this.exDataBK;
      ex.id = data.id;
      ex.title = data.title;
      ex.status = data.isActive.toString();

      gpf.showModal("#modal-data-bk");
    },
    async editBKApi() {
      gpf.gLoading.show();
      const hide = () => gpf.gLoading.hide();
      const ex = this.exDataBK;
      const params = new URLSearchParams();
      params.append("title", ex.title);
      params.append("status", ex.status);

      const url = api.adminBlogKategori + ex.id;
      const hitApi = await api.hitApiPut(url, params, hide);
      await gpf.handleSds(hitApi);
      await gpf.hideModal("#modal-data-bk");
      await this.emptyField();
      await this.getData();
    },
    deleteBK(data: any): void {
      const ex = this.exDeleteBK;
      ex.id = data.id;
      ex.title = data.title;

      gpf.showModal("#delete-modal-data-bk");
    },
    async deleteBKApi() {
      gpf.gLoading.show();

      const hide = () => gpf.gLoading.hide();
      const ex = this.exDeleteBK;

      const url = api.adminBlogKategori + ex.id;
      const hitApi = await api.hitApiDel(url, hide);
      ex.id = 0;
      ex.title = "";

      await gpf.handleSds(hitApi);
      await gpf.hideModal("#delete-modal-data-bk");
      await this.getData();
    }
  },
});
